<template>
  <toggle-section
    :is-open="isOpen[getSectionName('properties', index)]"
    :section="getSectionName('properties', index)"
    @toggle="toggleTable"
    chevron-position="right"
    class="rounded"
    :has-padding="false"
  >
    <template #titleSection>
      <div class="flex flex-grow items-center space-x-2 mr-2">
        <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.PROPERTIES") }}</lf-h3>
        <lf-pill size="sm" color-index="15">
          {{ business?.PropertySection?.PropertyRecordCount || "0" }}
        </lf-pill>
      </div>
    </template>
    <template #content>
      <div
        v-for="(property, propertyIndex) in getProperty(business)"
        :key="`property_${index}_${propertyIndex}`"
        class="even:bg-gray-50 p-5"
      >
        <div class="flex space-x-5 mb-5">
          <property-card class="w-1/2 p-5 rounded border space-x-5 bg-white">
            <div>{{ $t("DEALS.LEXIS_NEXIS.KYB.MARKET_LAND_VALUE") }}</div>
            <lf-h3>{{ property.MarketLandValue ?? "-" }}</lf-h3>
          </property-card>
          <property-card class="w-1/2 p-5 rounded border space-x-5 bg-white">
            <div>{{ $t("DEALS.LEXIS_NEXIS.KYB.TOTAL_MARKET_VALUE") }}</div>
            <lf-h3>{{ property.TotalMarketValue ?? "-" }}</lf-h3>
          </property-card>
        </div>
        <div class="rounded-lg border bg-white mb-9">
          <div class="p-5 flex flex-row space-x-2">
            <icon-base icon="address" class="mt-1" />
            <div class="flex flex-col space-y-0.5">
              <div class="font-semibold text-headline">
                {{
                  property.PropertyAddress?.State
                    ? getStateName(property.PropertyAddress.State)
                    : "-"
                }}
              </div>
              <div>
                {{ cleanAndFormatAddress(property.PropertyAddress) }}
              </div>
            </div>
          </div>
          <div v-if="property.AddressType" class="p-5 flex border-t">
            <lf-pill color-index="15" size="sm">
              {{ property.AddressType }}
            </lf-pill>
          </div>
        </div>
        <!-- Owners -->
        <div class="flex items-center justify-start space-x-2 mb-2">
          <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.OWNERS") }}</lf-h3>
          <lf-pill color-index="15" size="sm">
            {{ getOwners(property).length }}
          </lf-pill>
        </div>
        <template
          v-for="(owner, ownerIndex) in getOwners(property)"
          :key="ownerIndex"
        >
          <div class="rounded-lg border bg-white mb-5">
            <div class="flex items-center p-5 border-b justify-between">
              <div class="flex items-center space-x-2">
                <icon-base :icon="IconCompanySmallNoBg" class="-mt-1" />
                <lf-h3>
                  {{ owner.CompanyName }}
                </lf-h3>
              </div>
              <div class="flex space-x-4">
                <lf-pill size="sm">
                  {{ $t("DEALS.LEXIS_NEXIS.KYB.PROXY_NAME_SEQ") }} &bull;
                  {{ owner.ProxyNameSeq }}
                </lf-pill>
                <div
                  class="rounded bg-gray-100 px-2 flex items-center justify-center"
                  v-tooltip="$t('DEALS.LEXIS_NEXIS.KYB.PARTY_TYPE')"
                >
                  {{ owner.PartyType }}
                </div>
              </div>
            </div>
            <div
              v-if="owner.Address"
              class="p-5 flex flex-row space-x-2 border-b"
            >
              <icon-base icon="address" class="mt-1" />
              <div class="flex flex-col space-y-0.5">
                <div class="font-semibold text-headline">
                  {{
                    owner.Address?.State
                      ? getStateName(owner.Address.State)
                      : "-"
                  }}
                </div>
                <div>
                  {{ cleanAndFormatAddress(owner.Address) }}
                </div>
              </div>
            </div>
            <toggle-section
              :is-open="isOpen[getSectionName('owners', index, ownerIndex)]"
              :section="getSectionName('owners', index, ownerIndex)"
              @toggle="toggleTable"
              chevron-position="right"
              :has-border="false"
              :has-padding="false"
            >
              <template #titleSection>
                {{ $t("DEALS.LEXIS_NEXIS.KYB.BUSINESS_IDENTITY") }}
              </template>
              <template #content>
                <div class="bg-gray-100 p-5 rounded-b-lg">
                  <div class="grid grid-cols-2 gap-4">
                    <div
                      class="grid grid-cols-2 grid-rows-4 gap-2 pr-4 border-r"
                    >
                      <template
                        v-for="(id, idIndex) in Object.entries(
                          owner.BusinessIdentity
                        ).slice(0, 4)"
                        :key="idIndex"
                      >
                        <div>{{ camelCaseToWords(id[0]) }}</div>
                        <div class="text-right text-headline">
                          {{ id[1] }}
                        </div>
                      </template>
                    </div>
                    <div class="grid grid-cols-2 grid-rows-4 gap-2">
                      <template
                        v-for="(id, idIndex) in Object.entries(
                          owner.BusinessIdentity
                        ).slice(4)"
                        :key="idIndex"
                      >
                        <div>{{ camelCaseToWords(id[0]) }}</div>
                        <div class="text-right text-headline">
                          {{ id[1] }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </toggle-section>
          </div>
        </template>
        <!-- Sellers -->
        <div class="flex items-center justify-start space-x-2 mt-9 mb-2">
          <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.SELLERS") }}</lf-h3>
          <lf-pill color-index="15" size="sm">
            {{ getSellers(property).length }}
          </lf-pill>
        </div>
        <template
          v-for="(seller, sellerIndex) in getSellers(property)"
          :key="sellerIndex"
        >
          <div class="rounded-lg border bg-white mb-5">
            <div class="flex items-center p-5 border-b justify-between">
              <div class="flex items-center space-x-2">
                <icon-base icon="user" class="-mt-5" />
                <div
                  v-if="seller.Name || seller.CompanyName"
                  class="flex flex-col"
                >
                  <lf-h3>
                    {{
                      seller.Name ? getName(seller.Name) : seller.CompanyName
                    }}
                  </lf-h3>
                  <div>
                    {{ $t("DEALS.LEXIS_NEXIS.KYB.SELLER") }}
                  </div>
                </div>
              </div>
              <div class="flex space-x-4">
                <lf-pill size="sm">
                  {{ $t("DEALS.LEXIS_NEXIS.KYB.PROXY_NAME_SEQ") }} &bull;
                  {{ seller.ProxyNameSeq }}
                </lf-pill>
                <div
                  class="rounded bg-gray-100 px-2 flex items-center justify-center"
                  v-tooltip="$t('DEALS.LEXIS_NEXIS.KYB.PARTY_TYPE')"
                >
                  {{ seller.PartyType }}
                </div>
              </div>
            </div>
            <toggle-section
              :is-open="isOpen[getSectionName('sellers', index, sellerIndex)]"
              :section="getSectionName('sellers', index, sellerIndex)"
              @toggle="toggleTable"
              chevron-position="right"
              :has-border="false"
              :has-padding="false"
            >
              <template #titleSection>
                {{ $t("DEALS.LEXIS_NEXIS.KYB.BUSINESS_IDENTITY") }}
              </template>
              <template #content>
                <div class="bg-gray-100 p-5 rounded-b-lg">
                  <div class="grid grid-cols-2 gap-4">
                    <div
                      class="grid grid-cols-2 grid-rows-4 gap-2 pr-4 border-r"
                    >
                      <template
                        v-for="(id, idIndex) in Object.entries(
                          seller.BusinessIdentity
                        ).slice(0, 4)"
                        :key="idIndex"
                      >
                        <div>{{ camelCaseToWords(id[0]) }}</div>
                        <div class="text-right text-headline">
                          {{ id[1] }}
                        </div>
                      </template>
                    </div>
                    <div class="grid grid-cols-2 grid-rows-4 gap-2">
                      <template
                        v-for="(id, idIndex) in Object.entries(
                          seller.BusinessIdentity
                        ).slice(4)"
                        :key="idIndex"
                      >
                        <div>{{ camelCaseToWords(id[0]) }}</div>
                        <div class="text-right text-headline">
                          {{ id[1] }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </toggle-section>
          </div>
        </template>
        <!-- Mortgages -->
        <div class="flex items-center justify-start space-x-2 mt-9 mb-2">
          <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.MORTGAGES") }}</lf-h3>
          <lf-pill color-index="15" size="sm">
            {{ getMortgages(property).length }}
          </lf-pill>
        </div>
        <template
          v-for="(mortgage, mortgageIndex) in getMortgages(property)"
          :key="mortgageIndex"
        >
          <div class="rounded-lg border bg-white mb-5">
            <div class="flex items-center p-5 border-b justify-between">
              <div class="flex items-center space-x-2">
                <icon-base icon="offer" class="-mt-5" />
                <div class="flex flex-col">
                  <lf-h3>
                    {{ mortgage.Description || mortgage.LenderName || "-" }}
                  </lf-h3>
                  <div>
                    {{ $t("DEALS.LEXIS_NEXIS.KYB.MORTGAGE") }}
                  </div>
                </div>
              </div>
              <div class="flex space-x-4">
                <div>{{ $t("DEALS.LEXIS_NEXIS.KYB.LOAN_DATE") }}</div>
                <div class="text-headline">
                  {{ formatDate(mortgage.LoanDate) }}
                </div>
              </div>
            </div>
            <div class="p-5">
              <div class="grid grid-cols-2 gap-4">
                <div class="grid grid-cols-2 gap-2 pr-4 border-r">
                  <div>
                    {{ $t("DEALS.LEXIS_NEXIS.KYB.CONTRACT_DATE") }}
                  </div>
                  <div class="text-right text-headline">
                    {{ formatDate(mortgage.ContractDate) }}
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-2">
                  <div>
                    {{ $t("DEALS.LEXIS_NEXIS.KYB.RECORDING_DATE") }}
                  </div>
                  <div class="text-right text-headline">
                    {{ formatDate(mortgage.RecordingDate) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- Assessor -->
        <template v-if="property.Accessor">
          <div class="flex items-center justify-start space-x-2 mt-9 mb-2">
            <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.ASSESSOR") }}</lf-h3>
          </div>
          <div class="rounded-lg border bg-white">
            <div class="flex items-center p-5 border-b justify-between">
              <div class="flex items-center space-x-2">
                <icon-base icon="address" class="-mt-5" />
                <div class="flex flex-col">
                  <lf-h3>
                    {{ property.Accessor.LegalSubdivisionName || "-" }}
                  </lf-h3>
                  <div>
                    {{ property.Accessor.CountyLandUseDescription || "-" }}
                  </div>
                </div>
              </div>
              <div class="flex space-x-4">
                <div>{{ $t("DEALS.LEXIS_NEXIS.KYB.TAX_YEAR") }}</div>
                <div class="text-headline">
                  {{ property.Accessor?.TaxYear || "-" }}
                </div>
              </div>
            </div>
            <div class="p-5 flex space-x-2 border-b">
              <lf-pill
                v-if="property.Accessor.AssesseeRelationshipDesc"
                color-index="15"
                size="sm"
              >
                {{ property.Accessor.AssesseeRelationshipDesc }}
              </lf-pill>
              <lf-pill
                v-if="property.Accessor.StandardizedLandUseDesc"
                color-index="15"
                size="sm"
              >
                {{ property.Accessor?.StandardizedLandUseDesc }}
              </lf-pill>
            </div>
            <div class="grid grid-cols-3 grid-rows-2 assessor-grid">
              <property-card
                icon="offer"
                :icon-size="{ width: 36, height: 36 }"
                view-box="-4 -5 24 24"
              >
                <div>
                  {{ $t("DEALS.LEXIS_NEXIS.KYB.ASSESSED_TOTAL_VALUE") }}
                </div>
                <lf-h3>
                  {{ formatMoney(property.Accessor.AssessedTotalValue) || "-" }}
                </lf-h3>
              </property-card>
              <property-card :icon="IconMarketImprovement">
                <div>
                  {{ $t("DEALS.LEXIS_NEXIS.KYB.MARKET_IMPROVEMENT_VALUE") }}
                </div>
                <lf-h3>
                  {{
                    formatMoney(property.Accessor.MarketImprovementValue) || "-"
                  }}
                </lf-h3>
              </property-card>
              <property-card>
                <div>{{ $t("DEALS.LEXIS_NEXIS.KYB.TAX_AMOUNT") }}</div>
                <lf-h3>
                  {{ formatMoney(property.Accessor.TaxAmount) || "-" }}
                </lf-h3>
              </property-card>
              <property-card :icon="IconBath">
                <div>{{ $t("DEALS.LEXIS_NEXIS.KYB.NUM_BATHS") }}</div>
                <lf-h3>{{ property.Accessor.NumberOfBaths || "-" }}</lf-h3>
              </property-card>
              <property-card :icon="IconBed">
                <div>{{ $t("DEALS.LEXIS_NEXIS.KYB.NUM_BEDS") }}</div>
                <lf-h3>{{ property.Accessor.NumberOfBedrooms || "-" }}</lf-h3>
              </property-card>
              <property-card :icon="IconGarage" view-box="0 0 48 48">
                <div>{{ $t("DEALS.LEXIS_NEXIS.KYB.GARAGE") }}</div>
                <lf-h3>{{ property.Accessor.GarageTypeCode || "-" }}</lf-h3>
              </property-card>
            </div>
            <div class="grid grid-cols-2 assessor-grid-2">
              <property-card :icon="IconHeating" view-box="0 0 48 48">
                <div class="flex justify-between items-center">
                  <div class="flex flex-col items-start">
                    <div>{{ $t("DEALS.LEXIS_NEXIS.KYB.HEATING") }}</div>
                    <div
                      class="rounded bg-gray-100 px-2 flex items-center justify-center"
                    >
                      {{ property.Accessor.HeatingCode || "-" }}
                    </div>
                  </div>
                  <div class="font-semibold text-headline">
                    {{ property.Accessor.HeatingDesc || "-" }}
                  </div>
                </div>
              </property-card>
              <property-card :icon="IconAirConditioning" view-box="0 0 48 48">
                <div class="flex justify-between items-center">
                  <div class="flex flex-col items-start">
                    <div>
                      {{ $t("DEALS.LEXIS_NEXIS.KYB.AIR_CONDITIONING") }}
                    </div>
                    <div
                      class="rounded bg-gray-100 px-2 flex items-center justify-center"
                    >
                      {{ property.Accessor.AirConditioningCode || "-" }}
                    </div>
                  </div>
                  <div class="font-semibold text-headline">
                    {{ property.Accessor.AirConditioningDesc || "-" }}
                  </div>
                </div>
              </property-card>
            </div>
            <div class="bg-gray-100 p-5">
              <div class="grid grid-cols-2">
                <div class="grid grid-cols-2 grid-rows-4 gap-2 pr-5 border-r">
                  <template
                    v-for="(assessor, assessorIndex) in getAssessors(
                      property.Accessor
                    ).slice(0, 4)"
                    :key="assessorIndex"
                  >
                    <div>{{ camelCaseToWords(assessor[0]) }}</div>
                    <div class="text-right text-headline">
                      {{ assessor[1] || "-" }}
                    </div>
                  </template>
                </div>
                <div class="grid grid-cols-2 grid-rows-4 gap-2 pl-5">
                  <template
                    v-for="(assessor, assessorIndex) in getAssessors(
                      property.Accessor
                    ).slice(4)"
                    :key="assessorIndex"
                  >
                    <div>{{ camelCaseToWords(assessor[0]) }}</div>
                    <div class="text-right text-headline">
                      {{ assessor[1] || "-" }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </toggle-section>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import ToggleSection from "@/components/ToggleSection.vue";
import { formatMoney, camelCaseToWords } from "@/helpers/formatting";
import {
  getSectionName,
  getName,
  getStateName,
  cleanAndFormatAddress
} from "@/helpers/lexisnexis";
import PropertyCard from "./PropertyCard.vue";
import IconMarketImprovement from "@/components/icons/IconMarketImprovement.vue";
import IconBath from "@/components/icons/IconBath.vue";
import IconBed from "@/components/icons/IconBed.vue";
import IconGarage from "@/components/icons/IconGarage.vue";
import IconHeating from "@/components/icons/IconHeating.vue";
import IconAirConditioning from "@/components/icons/IconAirConditioning.vue";
import IconCompanySmallNoBg from "@/components/icons/IconCompanySmallNoBg.vue";
import type {
  IAccessor,
  AddressToDate,
  Mortgage,
  Owner,
  Party,
  IProperty,
  Seller,
  Business
} from "@/models/commercialData/lexisnexis/KybReport";
defineProps({
  business: {
    type: Object as PropType<Business>,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  isOpen: {
    type: Object,
    required: true
  }
});

const emit = defineEmits<{
  (eventName: "toggle", key: string): void;
}>();
const toggleTable = (section: string) => {
  emit("toggle", section);
};

const getProperty = (business: Business) => {
  return business.PropertySection?.PropertyRecords.Properties?.Property || [];
};

const getOwners = (property: IProperty) => {
  const parties = property.Parties?.Party || [];
  return parties.reduce((acc: Owner[], party: Party) => {
    const owners = party.Owners.Owner;
    if (owners.length) {
      return [...acc, ...owners];
    }
    return acc;
  }, []);
};

const getSellers = (property: IProperty) => {
  const parties = property.Parties?.Party || [];
  return parties.reduce((acc: Seller[], party: Party) => {
    const sellers = party.Sellers?.Seller || [];
    if (sellers.length) {
      return [...acc, ...sellers];
    }
    return acc;
  }, []);
};

const getMortgages = (property: IProperty) => {
  const parties = property.Parties?.Party || [];
  return parties.reduce((acc: Mortgage[], party: Party) => {
    const mortgages = party.Mortgages?.Mortgage || [];
    if (mortgages.length) {
      return [...acc, ...mortgages];
    }
    return acc;
  }, []);
};

const getAssessors = (assessor: IAccessor) => {
  return Object.entries({
    FipsCode: assessor.FipsCode,
    StateCode: assessor.StateCode,
    LegalLotNumber: assessor.LegalLotNumber,
    LandSquareFootage: assessor.LandSquareFootage,
    YearBuilt: assessor.YearBuilt,
    Stories: assessor.NumberOfStories,
    AssessedValueYear: assessor.AssessedValueYear,
    StandardizedLandUseCode: assessor.StandardizedLandUseCode
  });
};

const formatDate = (date: AddressToDate) => {
  return `${date.Year}-${date.Month}-${date.Day}`;
};
</script>

<style scoped>
.assessor-grid > div,
.assessor-grid-2 > div {
  @apply p-5 border-r border-b;
}
.assessor-grid > div:nth-child(3n) {
  @apply border-r-0;
}
.assessor-grid-2 > div:nth-child(2n) {
  @apply border-r-0;
}
</style>
